/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from '../app-api-client'

const pageUriPrefix = "/admin/products/import";

export default {

    getIndexData() {
        return appApiClient().get(pageUriPrefix + '/index');
    },
    uploadProductFileFormData(data) {
        const formData = new FormData();
        let headers = {};
        if (data.file != null) {
            formData.append('file', data.file);
            headers = {'Content-Type': 'multipart/form-data'};
        }
        return appApiClient().post(pageUriPrefix + '/upload-products', formData, headers);
    },
}